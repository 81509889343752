import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import Img from "gatsby-image"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { animeFadeUp, animeSlideOutRight, animeFadeIn } from "../utils/Animations"
import TransitionLink from "gatsby-plugin-transition-link"

const ServicesPage = ({ data, transitionStatus}) => {



  const services = [
    {
      name: 'Bar à cocktails mariage',
      url: '/services/bar-à-cocktails-mariage',
      text: 'Être présent le jour J avec un haut niveau de prestation, c’est tout le sens de notre service bar à cocktails.',
      image: data.mariage,
    },
    {
      name: 'Activation de marque',
      url: '/services/activation-de-marque',
      text: `Belladrinks met à disposition le savoir-faire de son équipe auprès des acteurs de l’industrie des spiritueux.`,
      image: data.activation,
    },
    {
      name: 'Bar à cocktails événementiel',
      url: '/services/bar-à-cocktails-événementiel',
      text: `Dynamisez votre événement et marquez les esprits avec notre service de bar à cocktails.`,
      image: data.bar,
    },
    {
      name: 'Atelier cocktails',
      url: '/services/atelier-cocktails',
      text: 'Entre amis ou pendant un teambuilding, apprenez à réaliser des cocktails au contact d’un professionnel.',
      image: data.atelier,
    },
  ]



  const listServices = services.map((service, index) =>
    <InView key={index} triggerOnce={true} threshold={0.35} skip={transitionStatus !== "entered"}>
      {({ inView, ref, entry }) => (
        <motion.div
          initial={false}
          animate={inView ? "visible" : "hidden"}
          ref={ref}
          className="grid grid-cols-1 md:grid-cols-12"
        >
          <motion.div
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  ease: "easeOut",
                  duration: "0.6",
                  staggerChildren: 0.4
                }
              },
              hidden: {
                opacity: 0,
                y: '40px'
              }
            }}
            className="bg-white py-12 my-0 flex items-center z-20 px-8 lg:my-12 md:px-14 md:row-start-1 md:col-start-1 md:col-end-7 lg:col-end-6 xl:col-end-6 2xl:col-end-5 md:py-20 2xl:py-24 2xl:px-20">
            <div>
              <motion.h2 variants={animeFadeUp} className="display-2">
                {service.name}
              </motion.h2>
              <motion.div
                variants={{
                  hidden:{
                    width: 0
                  },
                  visible: {
                    width: '6rem',
                    transition: {
                      ease: "easeOut",
                      duration: "0.6"
                    }
                  }
                }}
                className="border-b-4 border-copper mt-4 mb-8"
              />
              <motion.p variants={animeFadeIn} className="mb-6 text-xl leading-loose">
                {service.text}
              </motion.p>
              <motion.div
                className="inline-block"
                variants={animeFadeIn}
              >
                <TransitionLink
                  className="link"
                  to={service.url}
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Plus d'informations
                </TransitionLink>
              </motion.div>
            </div>
          </motion.div>
          <div className="row-auto h-64 order-first relative md:order-none md:h-auto md:row-start-1 md:col-start-7 md:col-end-13 lg:col-start-4">
            <div className="absolute inset-0 overflow-hidden">
              <motion.div variants={animeSlideOutRight} className="absolute inset-0 bg-pampas z-10"/>
              <Img
                className="h-full"
                fluid={service.image.childImageSharp.fluid}
              />
            </div>
          </div>
        </motion.div>
      )}
    </InView>
  )

  return (
    <Layout>
      <SEO
        title="Bar à cocktails événementiel et atelier cocktails - Belladrinks bar à cocktails événementiel"
        description="Animez vos événements avec notre service de bar à cocktails événementiel. Apprenez à réaliser des cocktails pendant un atelier cocktails."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Nos services"}/>
      <section className="py-24">
        <div className="main-container gap-y-8 lg:gap-y-24 grid md:grid-flow-row md:auto-rows-fr">
          {listServices}
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "header/helena-lopes-wtVhGlCKcl4-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundCocktail: file(relativePath: { eq: "cocktails/etea.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        activation: file(relativePath: { eq: "services/activation/stanislav-ivanitskiy-ct1uNvddgMk-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atelier: file(relativePath: { eq: "atelier.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bar: file(relativePath: { eq: "services/bar/closeup-barman-making-margaritas-with-five-glasses-set-line.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mariage: file(relativePath: { eq: "mariage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
